export default class RealAdmin {
  constructor() {
    this.setupInplaces();
    this.setupDeletes();
    this.setupVendasEventosDisables();
  }

  setupInplaces() {
    jQuery(".inplace-validado_financeiro").click(function() {
      var id, url;
      id = $(this).attr("data-id");
      url = "/vendas/" + id + "/valida_financeiro?format=js";
      return jQuery.ajax({
        url: url,
        type: "POST",
        data: { id: id },
        dataType: "json",
        success: function(res, status) {
          return $("#valida_financeiro_" + res.id).html(res.status);
        },
        error: function(result, status) {
          var res;
          res = $.parseJSON(result.responseText);
          return $("#valida_financeiro_" + res.id).html(res.status);
        }
      });
    });
    return jQuery(".transactions_check").click(function() {
      var check, contabil, trans;
      trans = jQuery(this).val();
      check = jQuery(this).attr("checked");
      contabil = jQuery("#contabil_id").val();
      return jQuery.ajax({
        type: "POST",
        url: "/contabeis/transacoes",
        data: "id=" + contabil + "&transacao_id=" + trans + "&check=" + check
      });
    });
  }

  setupAjaxCheckbox() {
    return jQuery(".ajax-checkbox").unbind().click(function() {
      var check, clicked;
      clicked = $(this);
      check = clicked.html() === "☑" ? "☐" : "☑";
      jQuery.ajax({
        url: $(this).attr("href"),
        type: "get",
        dataType: 'html',
        success: (function(_this) {
          return function(request) {
            return clicked.html(check);
          };
        })(this)
      });
      return false;
    });
  }

  setupVendasEventosDisables(){
    function setEnabledFields() {
      let evento = $(this);
      let container = evento.closest('table');
      let campos = ['recebido', 'custo', 'tributavel', 'tributavel_pago', 'notas_fiscai_id'];
      let eventosPermite = {
        '1': ['recebido', 'notas_fiscai_id'], // Recebido
        '4': ['recebido', 'notas_fiscai_id'], // Reembolsado
        '2': ['custo', 'tributavel', 'tributavel_pago', 'notas_fiscai_id'], // Emitido
        '3': ['custo', 'tributavel', 'tributavel_pago', 'notas_fiscai_id'] // cancelado
      }
      let aceitos = eventosPermite[evento.val()];
      campos.map(function (c) {
        container.find('[name="vendas_evento[' + c + ']"]').attr('disabled', !aceitos.includes(c))
      })
    }
    let el = '#vendas_evento_evento';
    $(document).on('change', el, setEnabledFields);
    $(document).on("turbo:load", event => {
      console.log("LOADED?");
      $(el).trigger('change');
    })
  }

  setupDeletes() {
    return $(document).on("click", ".remove-this", function(event) {
      event.preventDefault();
      var obj = $(this);
      var dUrl = $(this).attr('href');
      if (confirm("Tem certeza mesmo?")) {
        console.log('URL:', dUrl);
        $.ajax({ url: dUrl, type: 'POST', data: {"_method":"delete"}, success: function(d) {
          return obj.closest("tr,li").fadeOut();
        }});
      }
      return false;
    });
  }
}