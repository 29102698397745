export default class ModelosDeContato {
  constructor(){
    let select = document.querySelector("#input-datalist-select");
    let modelo = document.querySelector("#modelo");
    let discount = document.querySelector('#sugerir_promocode');

    const sendRequest = () => {
      if (select.value != ''){
        let venda_id = document.querySelector("#venda_id").value;
        let option = document.querySelector(`option[value="${select.value}"]`);
        modelo.value = option.getAttribute(`data-value`);
        
        let url = `/vendas_contatos/modelo_email?venda_id=${venda_id}&modelo_id=${modelo.value}`;
        if (discount) {
          if (discount.value !== '' && typeof discount.value !== 'undefined') url = url + '&promocode=' + discount.value;
        }
        
        let planos = document.querySelector('#planos');
        if (planos) {
          if (planos.value !== '' && typeof planos.value !== 'undefined') url = url + '&planos=' + planos.value;
        }

        let request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.onload = function() {
          let data = JSON.parse(request.responseText);
          document.querySelector("#contato_titulo").value = data.titulo; 
          document.querySelector("#contato_mensagem").innerHTML = data.corpo;  
          let modelo_id = document.querySelector("#modelo_id");
          if(modelo_id) modelo_id.value = modelo.value; 
        
          window.setNewsEditorContent(data.corpo);
        };
        
        request.send();
      }
    }

    if(select) {
      select.addEventListener('keypress', event => {if (event.keyCode == '13') event.preventDefault();});
      select.onchange = () => {sendRequest();};
      if(discount) discount.onchange = () => {sendRequest();};
    }
  }
}